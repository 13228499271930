import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import {
  addCreditAuto,
  addCreditImmo,
  addAssurance,
  deleteAssurance,
  deleteCreditImmo,
  deleteCreditAuto,
  editProfil,
  editCreditImmo,
  editCreditAuto,
  editAssurance,
  noCompletCredit,
  deleteNoComplet
} from "../graphql/mutation";
import {
  getModel,
  getMarque,
  getAllCreditImmo,
  getAllCreditAuto,
  getAllCreditAnnonces,
  getAllCreditAssurances,
  getAllAnnonces,
  CheckPhone,
  getAllNoComplets
} from '../graphql/query';
import {
  noCompletCreated,
  creditAutoCreated,
  creditImmoCreated,
  AllNotification,
  ReadNotification
} from '../graphql/subscription';


import { Apollo } from 'apollo-angular';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    // 'xsrfCookieName':  'csrftoken',
    // 'xsrfHeaderName': 'X-CSRFToken'
  })
};

@Injectable()
export class CreditService {

  constructor(private http: HttpClient,
    // private commonService: CommonService,
    private router: Router,
    private apollo: Apollo) { }

  // private url = this.commonService.base_url;

  public checkGetModels() {


    return this.apollo.watchQuery<any>({
      query: getModel
    })


  }
  public checkGetMarque(value) {
    return this.apollo.watchQuery<any>({
      query: getMarque,
      variables: {
        make_id: value
      }
    })

  }

  public addCreditAuto(credit, profil) {
    return this.apollo.mutate({
      mutation: addCreditAuto,
      variables: {
        credit: credit,
        profil: profil
      }
    })

  }
  public addCreditImmo(credit, profil) {
    return this.apollo.mutate({
      mutation: addCreditImmo,
      variables: {
        credit: credit,
        profil: profil
      }
    })

  }
  public getAllCreditImmo() {
    return this.apollo.watchQuery<any>({
      query: getAllCreditImmo,
    })

  }
  public getAllCreditAuto() {
    return this.apollo.watchQuery<any>({
      query: getAllCreditAuto,
    })
  }
  public getAllTransfer() {
    return this.apollo
      .watchQuery<any>({
        query: getAllAnnonces,
        pollInterval: 10000

      })
  }

  public getAllAssurances() {
    return this.apollo.watchQuery<any>({
      query: getAllCreditAssurances,
    })
  }

  public addAssurance(assurance) {
    return this.apollo.mutate({
      mutation: addAssurance,
      variables: {
        assurance: assurance

      }
    })

  }
  public deleteAssurance(Id) {
    return this.apollo.mutate({
      mutation: deleteAssurance,
      variables: {
        id: Id,
      }
    })

  }
  public deleteCreditImmo(Id, IdProfil) {
    return this.apollo.mutate({
      mutation: deleteCreditImmo,
      variables: {
        id: Id,
        profil: IdProfil,
      }
    })

  }
  public deleteCreditAuto(Id, IdProfil) {
    return this.apollo.mutate({
      mutation: deleteCreditAuto,
      variables: {
        id: Id,
        profil: IdProfil,
      }
    })

  }

  public checkPhone(value) {
    return this.apollo.watchQuery<any>({
      query: CheckPhone,
      variables: {
        phone: value
      }
    })

  }
  public editProfil(Id, profil) {
    return this.apollo.mutate({
      mutation: editProfil,
      variables: {
        id: Id,
        profil: profil,
      }
    })

  }
  public editCreditImmo(Id, credit) {
    return this.apollo.mutate({
      mutation: editCreditImmo,
      variables: {
        id: Id,
        credit: credit,
      }
    })

  }
  public noCompletCredit(noComplet) {
    return this.apollo.mutate({
      mutation: noCompletCredit,
      variables: {
        noComplet: noComplet
      }
    })
  }
  public editCreditAuto(Id, credit) {
    return this.apollo.mutate({
      mutation: editCreditAuto,
      variables: {
        id: Id,
        credit: credit,
      }
    })

  }
  public editAssurance(Id, assurance) {
    return this.apollo.mutate({
      mutation: editAssurance,
      variables: {
        id: Id,
        assurance: assurance,
      }
    })

  }
  public getAllNoComplets() {
    return this.apollo.watchQuery<any>({
      query: getAllNoComplets,
    })
  }

  public deleteNoComplet(Id) {
    return this.apollo.mutate({
      mutation: deleteNoComplet,
      variables: {
        id: Id,
      }
    })

  }
  //  ********* Subscriptions ************


  public noCompletCreated() {
    return this.apollo.subscribe({
      query: noCompletCreated

    })
  }
  public creditImmoCreated() {
    return this.apollo.subscribe({
      query: creditImmoCreated

    })
  }
  public creditautoCreated() {
    return this.apollo.subscribe({
      query: creditAutoCreated

    })
  }
  public getAllNotifications() {
    return this.apollo.watchQuery<any>({
      query: AllNotification,
      pollInterval:3000
    })

  }
  public readNotification(id, user) {
    return this.apollo.mutate({
      mutation: ReadNotification,
      variables: {
        id: id,
        user: user
      }
    })

  }
}




