import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http'
import { Router } from '@angular/router';

import {uploadFile , addAnnonce,changeStatus,deleteAnnonce,editAnnonce} from '../graphql/mutation';
import { getAllAnnonces,getAllAnnoncesPublished } from '../graphql/query';
import {annonceCreated} from '../graphql/subscription'
import { Apollo } from 'apollo-angular';

 
@Injectable()
export class AnnonceService {

  constructor(private http: HttpClient,
 // private commonService: CommonService,
  private router: Router,
  private apollo:Apollo) { }
  
 // private url = this.commonService.base_url;
 CLOUDINARY_URL="https://api.cloudinary.com/v1_1/creditbox/image/upload"
 public  uploadFile(file) {
    const formData = new FormData()
    formData.append('file',file)
    formData.append('upload_preset',"creditbox")
     
    return  this.http.post<any>(this.CLOUDINARY_URL,formData)
   
     
  }
  public  addAnnonce(annonce,profil){
    return  this.apollo.mutate({
      mutation: addAnnonce,
      variables: {
         annonce:annonce,
         profil:profil
      }
    })
   
  }
  public annonceCreated(){
    return this.apollo.subscribe({
      query:annonceCreated

    })
  }
 
  public  getAllannonces(){
    
   
  return  this.apollo
  .watchQuery<any>({
    query: getAllAnnonces,
    
  })
  }
  public  getAllannoncesPublished(){
    
   
    return  this.apollo
    .watchQuery<any>({
      query: getAllAnnoncesPublished,
      
    })
    }
  public  changeStatus(Id,Status){
    console.log("iddd",Id)
    return  this.apollo.mutate({
      mutation: changeStatus,
      variables: {
         id:Id,
         status:Status,
      }
    })
   
  }
  public  deleteAnnonce(Id,IdProfil){
    return  this.apollo.mutate({
      mutation: deleteAnnonce,
      variables: {
         id:Id,
         profil:IdProfil,
      }
    })
   
  }
  public  editAnnonce(Id,annonce){
    return  this.apollo.mutate({
      mutation: editAnnonce,
      variables: {
         id:Id,
         annonce:annonce,
      }
    })
   
  }



  }

 


