import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service'; 

@Component({
  selector: 'app-our-services',
  templateUrl: './our-services.component.html',
  styleUrls: ['./our-services.component.scss'],

})
export class OurServicesComponent implements OnInit {
  public SpicialService = [];
  public serviceTypes = [];
  public Aux = [];
  public fxFlexgtmd;
  public TestVal=0;
  public mode=0;
  public ClassVal=0;

constructor(public appService:AppService
  ) { }

  ngOnInit() {
    this.serviceTypes = this.appService.getServices();
    this.SpicialService = this.serviceTypes;
    this.fxFlexgtmd = 20;
  }
clickEvent(val){
  if(this.mode==1)
  {
    this.TestVal=1;
    this.ClassVal=1;
  this.Aux[0] = this.SpicialService[val-1];
  this.SpicialService[val-1] = this.SpicialService[0];
  this.SpicialService[0] = this.Aux[0];
  for( let index in this.SpicialService)
  {
delete this.SpicialService[index+1];
this.SpicialService.length--;
  }
  this.SpicialService.length--;
  this.fxFlexgtmd = 100;
  this.mode=0;
}
else
{
  this.TestVal=0;
  this.ClassVal=0;
  this.serviceTypes = this.appService.getServices();
  this.SpicialService = this.serviceTypes;
  this.fxFlexgtmd = 20;
  this.mode=1;
}

}

}