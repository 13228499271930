import gql from "graphql-tag";
export const annonceCreated = gql`
subscription AnnonceCreated {
  annonceCreated {
    data {
      id
    }
  }
}
`;
export const noCompletCreated = gql`
subscription NoCompletCreated {
  noCompletCreated {
    data {
      id
      typeA
    }
  }
}
`;
export const creditImmoCreated = gql`
subscription CreditImmoCreated {
  creditImmoCreated {
    data {
      id
    }
  }
}
`;
export const creditAutoCreated = gql`
subscription CreditAutoCreated {
  creditAutoCreated {
    data {
      id
    }
  }
}
`;
export const AllNotification = gql`
query AllNotification {
  allNotification {
    id
    content
    type
    documentId
    documentType
    createdAt
    read
  }
}
`;
export const ReadNotification = gql`
mutation ReadNotification($id: String!, $user: String!) {
  readNotification(id: $id, user: $user) {
    id
  }
}
`;