import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service'; 
@Component({
  selector: 'app-mission',
  templateUrl: './mission.component.html',
  styleUrls: ['./mission.component.scss']
})
export class MissionComponent implements OnInit {
  public messionTypes = [];
  public SpecialMession = [];
  public fxFlexgtxs;
  public Aux = [];
  public TestVal=0;
  public mode=0;
  public ClassVal=0;
  constructor(public appService:AppService) { }

  ngOnInit() {
    this.messionTypes = this.appService.getMessions();
    this.SpecialMession = this.messionTypes;
    this.fxFlexgtxs=50;
  }
  clickEvent(val){
    if(this.mode==1)
    {
      this.TestVal=1;
      this.ClassVal=1;
    this.Aux[0] = this.SpecialMession[val-1];
    this.SpecialMession[val-1] = this.SpecialMession[0];
    this.SpecialMession[0] = this.Aux[0];
    for( let index in this.SpecialMession)
    {
  delete this.SpecialMession[index+1];
  this.SpecialMession.length--;
    }
    this.SpecialMession.length--;
    this.fxFlexgtxs = 100;
    this.mode=0;
  }
  else
  {
    this.TestVal=0;
    this.ClassVal=0;
    this.messionTypes = this.appService.getMessions();
    this.SpecialMession = this.messionTypes;
    this.fxFlexgtxs = 50;
    this.mode=1;
  }
  
  }

}
