import gql from "graphql-tag";

export const Login = gql`
  query login($email:String!,$password:String!) {
    login(email: $email,password: $password) {
      token
      user{
      _id
      email
      isAdmin
      fname
      lname
      phoneNo
      }
     
    }
  }
`;
export const getModel = gql`
  query makes{
    makes{
      id
      make
    }
  }
`;
export const getMarque = gql`
  query modelsbymake($make_id:String!) {
    modelsbymake(make_id: $make_id) {
      id
      model
     
    }
  }
`;
export const getAllAnnonces = gql`
  query getAllAnnonces {
    getAllAnnonces{
    id
    typeVeh
       
     marque
         
     modele
         
      typeCarburant
   
       boiteVitess
       annModel
    
   
      puisFiscal
    
    
      NbPlace
   
    NbPort
   
    KlmPar
    
   
    color
    
    SocieteFinas
    
   
    typeContrat
     
    NumCnt
   
    datFinCnt
    
    dureeCnt
     
    appInit
    published
      
      mensualite
      
      NBMoisRest
      
      NBMoisImpayes
    
      appVerser
    
      OptAchat
    
      infoCompl
    
    images
     profil{
       id
       lname
       fname
       email
       phone
       cin
       datnes
       adress
       stfamily
       bankType
       profType
       revenuM
       primAnn
     }
    }
  }
`;
export const getAllCreditImmo = gql`
query getAllCreditsImmo{
  getAllCreditsImmo{
    id
    profil{
      id
    fname  
    lname
    datnes
    email 
    phone
    }
    situationBien 
    natureBien
    emplacement
    price
    creditType
    raison
    autoFinance
  }
}

`;
export const getAllCreditAuto = gql`
query getAllCreditsAuto{
  getAllCreditsAuto{
    id
    profil{
      id
    fname  
    lname
    datnes
    email 
    phone
    }
    marque 
  modele  
  price
  puisFis
  typeVeh 
  datCircul 
  profilC
  typeprofil
  nomSte
  activite
  datCreat
  }
}

`;
export const getAllCreditAnnonces = gql`
query getAllAnnonces{
  getAllAnnonces{
    id
typeVeh
marque
modele
typeCarburant
boiteVitess
annModel
puisFiscal
NbPlace
NbPort
KlmPar
color
SocieteFinas
typeContrat
NumCnt
datFinCnt
dureeCnt
appInit
published
mensualite
NBMoisRest
NBMoisImpayes
appVerser
OptAchat
infoCompl
  }
}

`;
export const getAllCreditAssurances = gql`
query getAllAssurances{
  getAllAssurances{
    id
    typeA
    montant
    Duree
    Banque
    Birthday
    phone
    email
    Value
    Matricule
    Cin
    type
  }
}
`;

export const CheckPhone = gql`
query CheckPhoneAvailability($phone: Int!) {
  checkPhoneAvailability(phone: $phone) {
    id
  }
}
`;
export const getAllAnnoncesPublished = gql`
query GetAllPublishedAnnonces {
  getAllPublishedAnnonces {
    id
    profil {
      fname
      lname
      datnes
      email
      phone
      adress
      cin
      stfamily
      bankType
      profType
      revenuM
      primAnn
    }
    typeVeh
    marque
    modele
    typeCarburant
    boiteVitess
    annModel
    puisFiscal
    NbPlace
    NbPort
    KlmPar
    color
    SocieteFinas
    typeContrat
    NumCnt
    datFinCnt
    dureeCnt
    appInit
    mensualite
    NBMoisRest
    NBMoisImpayes
    appVerser
    OptAchat
    infoCompl
    images
  }
}`;
export const getAllNoComplets = gql`
query Query {
  getAllNoComplets {
    id
    typeA
    phone
    email
    fullname
  }
}
`;