import { Component, OnInit } from '@angular/core';
import { SwiperConfigInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss']
})
export class TestimonialsComponent implements OnInit { 
  public testimonials;
  public config: SwiperConfigInterface = { };
  private pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination',
    clickable: true,
    
  };

  constructor(public appService:AppService) { }

  ngOnInit() {
    this.testimonials = this.appService.getTestimonials(); 
  }

  ngAfterViewInit(){
    this.config = {
      observer: true,
      slidesPerView: 'auto',
      spaceBetween: 10,       
      keyboard: true,
      navigation: true,
      pagination: this.pagination,
      grabCursor: true,        
      loop: false,
      preloadImages: false,
      lazy: true,  
      autoplay: {
        delay: 5000,
      },
       breakpoints: {
         480: {
         
          observer: false,
          
            
          },
         
         740:  {
          slidesPerView: 1,
          
          autoplay: {
           delay: 5000,
         },
         },
         960: {
         slidesPerView: 1,
         
         autoplay: {
          delay: 5000,
        },
        }
      }
    }
  }

}
