import { Injectable } from '@angular/core';
//import { Http } from "@angular/http";
import { HttpHeaders, HttpClient } from '@angular/common/http'
//import { Observable } from 'rxjs/Observable';
//import { CommonService } from './common.service';

import { Router } from '@angular/router';

import {Login} from '../graphql/query';
import { Apollo } from 'apollo-angular';
import { Settings, AppSettings } from '../app.settings';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    // 'xsrfCookieName':  'csrftoken',
    // 'xsrfHeaderName': 'X-CSRFToken'
  })
};

@Injectable()
export class LoginService {
settings:Settings
  constructor(private http: HttpClient,
 // private commonService: CommonService,
  private router: Router,
  private apollo:Apollo,
  public appSettings:AppSettings,) { this.settings=appSettings.settings}
 
 // private url = this.commonService.base_url;
  
  checkUserLogin(data){
  //  console.log("dataaa",data)
    // return this.http.get(theUrl, JSON.stringify(data), httpOptions)
   // let postData = { 'emailPhone': data.emailPhno, 'password': data.loginPassword }
   // return this.http.post(this.url + '/auth/user/login', postData, httpOptions)



   return this.apollo.watchQuery({
      query: Login,
      variables: {
        email: data.email,
        password: data.password
      }
    })


  }
  private tokenExpired(token: string) {
    const expiry = (JSON.parse(window.atob(token.split('.')[1]))).exp;
    return (Math.floor((new Date).getTime() / 1000)) >= expiry;
  }
  isLoggedIn(){
    
    var token = localStorage.getItem('token');
    if(token){      
      if(this.tokenExpired(token))
        return true;
      else
      return false;
      // console.log('TokenExpirationDate', jwtHelper.getTokenExpirationDate(token));
      // console.log('TokenExpired ', jwtHelper.isTokenExpired(token));
    }
    else
      return false;   
  }

  logOut(){
    console.log("rrrrr")
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    this.settings.isAdmin=false;
    this.settings.user = null;
    this.router.navigate(['/login']);
  }

}
