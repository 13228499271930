import { Injectable } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { LoginService } from './login.service';

@Injectable()
export class AuthUserService implements CanActivate {
user:any;
  constructor(
    private loginService: LoginService,
    private router: Router
  ) { }

  canActivate(route, state: RouterStateSnapshot){
   
   
this.user= JSON.parse(localStorage.getItem('user'));
  if(this.user?.isAdmin) return true

    
  this.router.navigate(['login']);
  return false;
  }
}
