import { Component, OnInit, ViewChild, HostListener, AfterViewInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Settings, AppSettings } from '../app.settings';
//import { LoginService } from '../services/login.service';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { SharedService } from '../shared/shared.module';
import { LoginService } from '../services/login.service';
import {CreditService} from '../services/credit.service';
import {AnnonceService} from '../services/annonce.service';

export interface Notification {
  Id: string;
  title: string;
  href: string;
}
@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit {
  @ViewChild('sidenav', { static: true }) sidenav:any; 
  @ViewChild('drawer', { static: true }) drawer:any; 
  role:boolean;
  public toolbarTypes = [1, 2];
  public toolbarTypeOption:number;
  public headerTypes = ['default', 'image', 'carousel'];
  public headerTypeOption:string;
  public searchPanelVariants = [1, 2, 3];
  public searchPanelVariantOption:number;
  public headerFixed: boolean = false;
  public showBackToTop: boolean = false;
  public scrolledCount = 0;
  public user;
  public settings: Settings;
  public ShowDashboard;



  searchValue: string = null;





notifs: Notification[]=[];

  public navItems = [
    {id:1, icon: 'home', name: 'Immobilère', href:'creditimmo' },
    {id:2, icon: 'directions_car', name: 'Auto', href:'creditauto' },
    {id:3, icon: 'create_new_folder', name: 'Assurance', href:'assurence' },
    {id:4, icon: 'folder_open', name: 'Transfer', href:'transfer' },
    {id:5, icon: 'person_pin', name: 'Visiteurs', href:'visiteurs' }
];

  constructor(public appSettings:AppSettings,
              public router:Router,
              private loginservice:LoginService,
              private sharedService: SharedService,
              private annonceService: AnnonceService,
              private creditService: CreditService
              ) {
    this.settings = this.appSettings.settings; 
    this.creditService.getAllNotifications().valueChanges
    .subscribe(({ data, loading }) => {

      this.notifs = data?.allNotification.filter(item => item.read == false)
    })
//     // ********** Transfer ******
//     this.annonceService.annonceCreated().subscribe((result: any) => {
//       console.log("subscription", result.data.annonceCreated.data.id)
//       if (result.data.annonceCreated.data.id) {
//         this.notifs.push({Id:result.data.annonceCreated.data.id, title:"nouvelle annonce", href:"transfer"});
       
//       }
//     })
//  // ********** Credit Auto ******
//  this.creditService.creditautoCreated().subscribe((result: any) => {
//   console.log("creditAuto subscription", result)
//   if (result.data.creditAutoCreated.data.id) {
//     this.notifs.push({Id:result.data.creditAutoCreated.data.id, title:"nouvelle Credit Auto", href:"creditauto"});
    
//   }
// }) 
//   // ********** Credit Immo ******
//   this.creditService.creditImmoCreated().subscribe((result: any) => {
//     console.log("creditImmo subscription", result)
//     if (result.data.creditImmoCreated.data.id) {
//       this.notifs.push({Id:result.data.creditImmoCreated.data.id, title:"nouvelle Credit Immo", href:"creditimmo"});
     
//     }
//   })
//   // ********** No Complet ******
//   this.creditService.noCompletCreated().subscribe((result: any) => {
//     console.log("noComplet subscription", result)
//     if (result.data.noCompletCreated.data.id) {
//       this.notifs.push({Id:result.data.noCompletCreated.data.id, title:"nouveau visiteur", href:"visiteurs"});
      
//     }
//   })
  }

  ngOnInit() {
    this.toolbarTypeOption = this.settings.toolbar;    
    this.headerTypeOption = this.settings.header; 
    this.searchPanelVariantOption = this.settings.searchPanelVariant;
    this.user = JSON.parse(localStorage.getItem('user'));
    this.role = this.user ? this.user.isAdmin : true;
   if(this.user){
     this.settings.isAdmin = this.user.isAdmin;
     this.settings.user = this.user
   }
    this.ShowDashboard = 0;
  }
  go(Item){
this.creditService.readNotification(Item.id,this.settings.user._id).subscribe({
  next: d => {
    this.router.navigate(['/dashboard',Item.documentType])
  },
  error: error => {
    // handle error
  },
  complete: () => {
  }
});
    
  }
  DashShow(){
    if(this.ShowDashboard == 0)
    this.ShowDashboard=1;
    else
    this.ShowDashboard=0;
  }
  public changeTheme(theme){
    this.settings.theme = theme;       
  }

  public chooseToolbarType(){
    this.settings.toolbar = this.toolbarTypeOption;
    window.scrollTo(0,0);
  }

  public chooseHeaderType(){
    this.settings.header = this.headerTypeOption;    
    window.scrollTo(0,0);
    this.router.navigate(['home']);
  }

  public chooseSearchPanelVariant(){
    this.settings.searchPanelVariant = this.searchPanelVariantOption;
  }
     
 
  @HostListener('window:scroll') onWindowScroll() {
    const scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);
    (scrollTop > 300) ? this.showBackToTop = true : this.showBackToTop = false; 

    if(this.settings.stickyMenuToolbar){      
      let top_toolbar = document.getElementById('top-toolbar');
      if(top_toolbar){ 
        if(scrollTop >= top_toolbar.clientHeight) {
          this.settings.mainToolbarFixed = true;
        }
        else{
          this.settings.mainToolbarFixed = false;
        } 
      }        
    } 
    
        
    let load_more = document.getElementById('load-more');
    if(load_more){
      if(window.innerHeight > load_more.getBoundingClientRect().top + 120){ 
        if(!this.settings.loadMore.complete){
          if(this.settings.loadMore.start){        
            if(this.scrolledCount < this.settings.loadMore.step){  
              this.scrolledCount++; 
              if(!this.settings.loadMore.load){ 
                this.settings.loadMore.load = true; 
              }
            }
            else{
              this.settings.loadMore.start = false;
              this.scrolledCount = 0;
            }
          }  
        }              
      }
    }
  }

  public scrollToTop(){
    var scrollDuration = 200;
    var scrollStep = -window.pageYOffset  / (scrollDuration / 20);
    var scrollInterval = setInterval(()=>{
      if(window.pageYOffset != 0){
         window.scrollBy(0, scrollStep);
      }
      else{
        clearInterval(scrollInterval); 
      }
    },10);
    if(window.innerWidth <= 768){
      setTimeout(() => { window.scrollTo(0,0) });
    }
  }

  ngAfterViewInit(){
    document.getElementById('preloader').classList.add('hide');
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {        
       // this.sidenav.close();
        this.settings.mainToolbarFixed = false;
        setTimeout(() => {
          window.scrollTo(0,0);
        }); 
      }            
    });    
  }   
  onSubmit($event){
    this.sharedService.send_data.next(this.searchValue);
  }
  logOut(){
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    this.settings.isAdmin=false;
    this.settings.user = null;
    this.router.navigate(['/login']);
  }


 

}
