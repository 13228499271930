import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { FormBuilder, FormGroup } from '@angular/forms';

import { AppService } from '../../app.service'; 


@Component({

  selector: 'app-properties-search',

  templateUrl: './properties-search.component.html',

  styleUrls: ['./properties-search.component.scss']

})

export class PropertiesSearchComponent implements OnInit {

  @Input() variant:number = 1;

  @Input() vertical:boolean = false;

  @Input() searchOnBtnClick:boolean = true;

  @Input() removedSearchField:string;

  @Output() onSearchChange: EventEmitter<any> = new EventEmitter<any>();

  @Output() onSearchClick: EventEmitter<any> = new EventEmitter<any>();

  public showMore: boolean = false;

  public form: FormGroup;

  public features = [];

  typeVehicul='';
  typeCarb='';

  

  constructor(public appService:AppService, public fb: FormBuilder) { }


  ngOnInit() {

    if(this.vertical){

      this.showMore = true;

    };

    this.features = this.appService.getFeatures();

    this.form = this.fb.group({

      vehiculeType: null,

      typeCarburant: null, 

      boiteVitess:null, 

      annee: this.fb.group({

        from: null,

        to: null 

      }),

      klmt: this.fb.group({

        from: null,

        to: null 

      }),

      mensualite: this.fb.group({

        from: null,

        to: null 

      }), 

      features: this.buildFeatures()

    }); 


    this.onSearchChange.emit(this.form);

  }

 
  public buildFeatures() {

    const arr = this.features.map(feature => { 

      return this.fb.group({

        id: feature.id,

        name: feature.name,

        selected: feature.selected

      });

    })   

    return this.fb.array(arr);

  }

  

  ngOnChanges(){ 

    if(this.removedSearchField){ 

      if(this.removedSearchField.indexOf(".") > -1){

        let arr = this.removedSearchField.split(".");

        this.form.controls[arr[0]]['controls'][arr[1]].reset();

      } 

      else if(this.removedSearchField.indexOf(",") > -1){        

        let arr = this.removedSearchField.split(","); 

        this.form.controls[arr[0]]['controls'][arr[1]]['controls']['selected'].setValue(false);  

      }

      else{

        this.form.controls[this.removedSearchField].reset();

      }  

    }  

  }


  public reset(){     

    this.form.reset({ 

      vehiculeType: null,

      typeCarburant: null, 

      boiteVitess:null,

      annee: {

        from: null,

        to: null 

      },

      klmt: {

        from: null,

        to: null 

      },

      mensualite: {

        from: null,

        to: null 

      },

     
      features: this.features    

    }); 

  }


  public search(){

    this.onSearchClick.emit(); 

  }


  // public onSelectCity(){

  //   this.form.controls['neighborhood'].setValue(null, {emitEvent: false});

  //   this.form.controls['street'].setValue(null, {emitEvent: false});

  // }

  public onSelectNeighborhood(){

    this.form.controls['vehiculeType'].setValue(null, {emitEvent: false});

  }


  public getAppearance(){

    return (this.variant != 3) ? 'outline' : '';

  }

  public getFloatLabel(){

    return (this.variant == 1) ? 'always' : '';

  }



}
