import gql from "graphql-tag"; 


export const uploadFile = gql`
  mutation singleFileUpload($file: Upload!) {
    singleFileUpload(file: $file) {
  
      path
      public_Id    }
  }
`;
export const addAnnonce = gql`
  mutation addAnnonce($annonce: annonceInput!,$profil:profileInput!) {
    addAnnonce(annonce: $annonce,profil:$profil) {
      id
      profil {
        id
        fname
        lname
        datnes
        email
        phone
        adress
        cin
        stfamily
        bankType
        profType
        revenuM
        primAnn
      }
      typeVeh
      marque
      modele
      typeCarburant
      boiteVitess
      annModel
      puisFiscal
      NbPlace
      NbPort
      KlmPar
      color
      SocieteFinas
      typeContrat
      NumCnt
      datFinCnt
      dureeCnt
      appInit
      published
      mensualite
      NBMoisRest
      NBMoisImpayes
      appVerser
      OptAchat
      infoCompl
      images
    }
         
  }
`;


export const addCreditAuto = gql`
mutation addCreditAuto($credit: creditautoInput!, $profil:profileInput!){
    addCreditAuto(credit: $credit, profil: $profil) {
      id
      marque
      modele
    }
  }
`;
export const addCreditImmo = gql`
mutation addCreditImmo($credit: creditImmoInput!, $profil:profileInput!){
    addCreditImmo(credit: $credit, profil: $profil) {
      id
      situationBien
      profil{
        fname
      }
    }
  }
`;
export const addAssurance = gql`
mutation addAssurance($assurance: AssuranceInput!){
  addAssurance(assurance: $assurance) {
      id
      typeA
    }
  }
`;
export const changeStatus = gql`
mutation ChangeStatus($id: String!,$status:Boolean!) {
  changeStatus(id: $id,status: $status) {
    id
  }
}`;
export const deleteAnnonce = gql`
mutation DeleteAnnonce($id: String!, $profil: String!) {
  deleteAnnonce(id: $id, idProfil: $profil) {
    id
    profil {
      id
    }
  }
}
`;
export const deleteAssurance = gql`
mutation DeleteAssurance($id: String!) {
  deleteAssurance(id: $id) {
    id
  }
}
`;
export const deleteCreditImmo = gql`
mutation deleteCreditImmo($id: String!, $profil: String!) {
  deleteCreditImmo(id: $id, idProfil: $profil) {
    id
    profil {
      id
    }
  }
}
`;
export const deleteCreditAuto = gql`
mutation deleteCreditAuto($id: String!, $profil: String!) {
  deleteCreditAuto(id: $id, idProfil: $profil) {
    id
    profil {
      id
    }
  }
}
`;
export const editAnnonce = gql`
mutation EditAnnonce($id: String!, $annonce: annonceInput!) {
  editAnnonce(id: $id, annonce: $annonce) {
    id
  }
}
`;
export const editProfil = gql`
mutation EditProfil($id: String!, $profil: profilInput!) {
  editProfil(id: $id, profil: $profil) {
    id
    fname
  }
}
`;
export const editCreditImmo = gql`
mutation Mutation($id: String!, $credit: creditImmoInput!) {
  editCreditImmo(id: $id, credit: $credit) {
    id
  }
}
`;
export const noCompletCredit= gql`
mutation AddNoComplet($noComplet: NoCompletInput!) {
  addNoComplet(noComplet:$noComplet ) {
    id
    
  }
}
`;

export const editCreditAuto = gql`
mutation EditCreditAuto($id: String!, $credit: creditautoInput!) {
  editCreditAuto(id: $id, credit: $credit) {
    id
  }
}
`;
export const editAssurance = gql`
mutation Mutation($id: String!, $assurance: AssuranceInput!) {
  editAssurance(id: $id, assurance: $assurance) {
    id
  }
}
`;
export const deleteNoComplet = gql`
mutation Mutation($id: String!) {
  deleteNoComplet(id: $id) {
    id
  }
}`;