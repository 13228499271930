import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { Router } from '@angular/router';
import { Settings, AppSettings } from 'src/app/app.settings';
import { LoginService } from 'src/app/services/login.service';


@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {
  user;
  settings:Settings
  constructor(public appService:AppService, private router:Router, public appSettings: AppSettings,  private loginService: LoginService,) { 
   this.settings = this.appSettings.settings
  }

  ngOnInit() {
    this.user =JSON.parse(localStorage.getItem('user'));
    //console.log("DFZFZFZFZFZ",this.user)
    if(this.user){
      this.settings.isAdmin = this.user.isAdmin;
      this.settings.user = this.user
    }
 
}
}
